<script setup lang="ts">
import { ToggleGroupItem, ToggleGroupRoot, Separator } from "radix-vue";

import { Icon } from "@iconify/vue";

const route = useRoute();
const navigationStore = useNavigationStore();

const showFilters = computed(
  () =>
    (route.matched[0].path.startsWith("/submissions/:filter()") ||
      route.matched[0].path.startsWith("/claims/:filter()")) &&
    !navigationStore.collapsed
);

const store = useFilteringStore();

const hasAccess = useHasAccess();

const toggleGroupItemClasses = cn(
  "flex items-center gap-2 text-sm",
  "bg-transparent [&>svg]:hover:border-neutral-4 [&>svg]:hover:bg-neutral-2",
  "text-neutral-6 hocus:text-neutral-8"
);

const { data: categories } = useCategoriesGet();
</script>

<template>
  <div v-if="showFilters" class="p-2.5 mx-2.5 flex flex-col gap-2 bg-neutral-1 rounded-lg">
    <h4 class="small uppercase">{{ $t("mainnav.filters") }}</h4>
    <Separator
      class="h-px bg-neutral-3 data-[orientation=horizontal]:h-px data-[orientation=horizontal]:w-full"
    />
    <ToggleGroupRoot v-model="store.userClaimState" type="multiple" class="flex flex-col gap-2">
      <ToggleGroupItem
        value="isNew"
        :class="
          cn(
            toggleGroupItemClasses,
            '[&>svg]:data-[state=on]:bg-red-400 [&>svg]:data-[state=on]:border-red-400'
          )
        "
      >
        <Icon
          :icon="ICONS.new"
          class="transition border border-neutral-3 rounded-lg p-1 size-6 bg-neutral-0"
        />
        {{ $t("mainnav.new") }}
      </ToggleGroupItem>
      <ToggleGroupItem
        value="isMarked"
        :class="
          cn(
            toggleGroupItemClasses,
            '[&>svg]:data-[state=on]:bg-blue-300 [&>svg]:data-[state=on]:border-blue-300'
          )
        "
      >
        <Icon
          :icon="ICONS.bookmark"
          class="transition border border-neutral-3 rounded-lg p-1 size-6 bg-neutral-0"
        />
        {{ $t("mainnav.marked") }}
      </ToggleGroupItem>
      <ToggleGroupItem
        v-if="hasAccess.editor"
        value="isCommunity"
        :class="
          cn(
            toggleGroupItemClasses,
            '[&>svg]:data-[state=on]:bg-orange-300 [&>svg]:data-[state=on]:border-orange-300'
          )
        "
      >
        <Icon
          :icon="ICONS.community"
          class="transition border border-neutral-3 rounded-lg p-1 size-6 bg-neutral-0"
        />
        {{ $t("mainnav.community") }}
      </ToggleGroupItem>
    </ToggleGroupRoot>
    <Separator class="bg-neutral-3 data-[orientation=horizontal]:h-px data-[orientation=horizontal]:w-full" />

    <ToggleGroupRoot v-model="store.categories" type="multiple" class="flex flex-col gap-1">
      <ToggleGroupItem
        v-for="category in categories"
        :key="category.name"
        :value="category.name"
        :class="
          cn(
            toggleGroupItemClasses,
            'py-0.5 pl-0.5 transition',
            '[&_*]:data-[state=on]:text-neutral-8 [&_*]:hover:text-neutral-2'
          )
        "
      >
        <Icon
          :icon="ICONS.confirm"
          class="border border-neutral-3 rounded-lg p-1 size-6 bg-neutral-0 text-neutral-0"
        />

        {{ category.labelDe }}
      </ToggleGroupItem>
    </ToggleGroupRoot>
  </div>
</template>
