<script lang="ts" setup>
import Filters from "./Filters.vue";
import Links from "./Links.vue";
import Logo from "./Logo.vue";
import UserMenue from "./UserMenue.vue";

defineProps<{
  className?: string;
}>();
const store = useNavigationStore();
const { collapsed } = storeToRefs(store);
const hasAccess = useHasAccess();
</script>

<template>
  <nav
    :class="
      cn(
        'hidden sticky z-20 top-0 h-screen shrink-0 w-60 group md:flex flex-col items-center',
        'border-r border-neutral-4',
        className,
        {
          '!w-20': collapsed
        }
      )
    "
  >
    <Logo :collapsed />
    <div class="grow w-full scroll-area">
      <div class="flex flex-col gap-5 py-2.5">
        <Links />
        <Filters v-if="hasAccess.junior" />
      </div>
    </div>
    <footer class="w-full h-16 border-t flex flex-col justify-center gap-2.5 px-5">
      <UserMenue />
    </footer>
  </nav>
</template>
