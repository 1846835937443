<script lang="ts" setup>
defineProps<{
  className?: string;
  collapsed: boolean;
}>();
const colorMode = useColorMode();
</script>

<template>
  <NuxtLink
    to="/"
    :class="
      cn('relative flex items-center justify-center p-5 pt-[1.9em] w-full border-b', {
        'h-[100px]': !collapsed,
        'p-2.5': collapsed
      })
    "
  >
    <ClientOnly>
      <img
        v-if="collapsed"
        :src="colorMode.value === 'dark' ? '/logo-reduced-dark.svg' : '/logo-reduced-light.svg'"
        alt="Logo"
        class="w-full"
      />
      <img
        v-else
        :src="colorMode.value === 'dark' ? '/logo-complete-dark.svg' : '/logo-complete-light.svg'"
        alt="Logo"
        class="w-full"
      />
    </ClientOnly>
  </NuxtLink>
</template>
